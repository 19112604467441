.banner {
  margin-bottom: 24px;
  @include media-breakpoint-up(xl) {
    margin-bottom: 32px;
  }

  &__image {
    padding: 0;
    margin: 0 -20px;
    display: block;

    img {
      width: 100%;
      margin: 0 auto;
      display: block;
      position: relative;
      object-fit: cover;
      @include user-select;
      @include media-breakpoint-up(xss) {
        width: 100vw;
        max-width: 1920px;
        left: 50%;
        transform: translateX(-50%);
        [dir="rtl"] & {
          left: auto;
          right: 50%;
          transform: translateX(50%);
        }
      }
      @include media-breakpoint-down(md) {
        min-height: 298px;
        height: auto;
        max-height: 400px;
      }
      @include media-breakpoint-down(sm) {
        max-height: 360px;
      }
      @include media-breakpoint-down(xxl) {
        @include media-breakpoint-up(md) {
          height: 400px;
        }
        @include media-breakpoint-up(lg) {
          height: 460px;
        }
        @include media-breakpoint-up(xl) {
          height: 540px;
        }
      }

      &.mobile {
        & + img.desktop {
          display: none;
          visibility: hidden;
        }
        @include media-breakpoint-up(md) {
          display: none;
          & + img.desktop {
            display: block;
            visibility: visible;
          }
        }
      }
    }
  }
}