textarea {
  height: 58px;
  @include media-breakpoint-up(md) {
    height: 64px;
  }
}
.form {
  &-group {
    margin-bottom: 16px;
    @include media-breakpoint-up(md) {
      margin-bottom: 32px;
    }
  }

  &-label {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: black;
    display: block;
  }

  &-control {
    width: 100%;
    padding: 16px 20px 14px;
    border: none;
    border-bottom: 1px solid $color-gray;
    border-radius: 0;
    font: 18px/27px $font-default;
    color: $color-black;
    display: block;
    resize: none;
    background: transparent;
    outline: none !important;
    -webkit-appearance: none;
    &::placeholder { color: $color-gray; transition: opacity $effect; @include user-select; }
    &:focus::placeholder { opacity: 0; }
    @include media-breakpoint-up(xl) {
      padding-top: 16px;
      padding-bottom: 15px;
      font-size: 22px;
      line-height: 32px;
      font-weight: 300;
    }
  }

  &-select {
    width: 100%;
    padding: 16px 20px 14px;
    border: none;
    border-bottom: 1px solid $color-gray;
    border-radius: 0;
    font: 18px/27px $font-default;
    color: $color-black;
    display: block;
    background: transparent url($svg-select) no-repeat top 25px right 26px;
    outline: none !important;
    -webkit-appearance: none;
    @include media-breakpoint-up(xl) {
      padding-top: 16px;
      padding-bottom: 15px;
      font-size: 22px;
      line-height: 32px;
      font-weight: 300;
      background-position: top 28px right 27px;
    }
    [dir="rtl"] & {
      background-position: top 25px left 26px;;
      @include media-breakpoint-up(xl) {
        background-position: top 28px left 27px;
      }
    }
  }

  &-hidden {
    display: none;
  }
}

.btn {
  width: auto;
  min-width: 140px;
  height: auto;
  padding: 10px 40px 9px;
  border: 1px solid $color-green;
  border-radius: 24px;
  box-shadow: none;
  display: inline-block;
  font-size: 22px;
  line-height: 27px;
  font-weight: 400;
  color: white;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  transition: color $effect-s, background-color $effect-s;
  background: $color-green;
  cursor: pointer;
  outline: none !important;
  @include media-breakpoint-up(xl) {
    padding: 16px 44px;
    border-radius: 34px;
    font-size: 30px;
    line-height: 34px;
  }
  @media (hover: hover) {
    &:hover, &:focus {border-color: darken($color-green, 5%);background-color: darken($color-green, 5%);color: white;}
    &:active {border-color: $color-black;background-color: $color-black;}
  }

  &--outline {
    border-color: $color-black;
    color: $color-black;
    background-color: transparent;
    @media (hover: hover) {
      &:hover, &:focus {border-color: $color-green;color: white;background-color: $color-green;}
      &:active {border-color: $color-black;background-color: $color-black;}
    }
  }

  &--arrow {
    &::after {
      @include pseudo;
      width: 35px;
      height: 7px;
      margin: 0 6px 0 15px;
      display: inline-block;
      vertical-align: middle;
      background: url($svg-arrow) no-repeat center center;
      background-size: contain;
      transition: none;
      filter: brightness(10000%);
      @include media-breakpoint-up(xl) {
        margin-left: 22px;
      }
      [dir="rtl"] & {
        margin-right: 15px;
        margin-left: 6px;
        transform: scaleX(-1);
        @include media-breakpoint-up(xl) {
          margin-left: 0;
          margin-right: 22px;
        }
      }
    }

    &.btn--outline {
      transition: none;
      &::after {filter: brightness(0%);}
      @media (hover: hover) {
        &:hover, &:focus {
          &::after {filter: brightness(10000%);}
        }
        &:active {
          &::after {filter: brightness(10000%);}
        }
      }
    }
  }
}