.menu-short {
  margin-top: 47px;
  margin-bottom: 40px;

  &__heading {
    margin-bottom: 8px;
    @include media-breakpoint-up(lg) {
      margin-top: -15px;
      margin-bottom: 0;
    }
    @include media-breakpoint-up(xl) {
      margin-top: -24px;
    }
  }

  &__content {
    @include media-breakpoint-up(xl) {
      padding-left: 6px;
      [dir="rtl"] & {
        padding-right: 6px;
        padding-left: 0;
      }
    }
    p:last-of-type {margin-bottom: 0;}
  }

  &__list {
    margin-top: 32px;
    @include media-breakpoint-up(xl) {
      margin: 56px -8px 0;
    }
    [dir="rtl"] & {
      > .row {
        flex-direction: row-reverse;
      }
    }
  }

  &__item {
    margin-bottom: 29px;
    @include media-breakpoint-up(xl) {
      padding: 0 9px;
      margin-bottom: 56px;
    }

    a {
      display: block;
      text-decoration: none;
      @media (hover: hover) {
        &:hover, &:focus {
          h3 {color: $color-green;}
        }
      }
    }

    figure {
      margin: 0;

      img {
        width: 100%;
        height: auto;
        max-height: 261px;
        display: block;
        object-fit: cover;
        object-position: center center;
        @include user-select;

        &.mobile {
          & + img.desktop {
            display: none;
            visibility: hidden;
          }
          @include media-breakpoint-up(md) {
            display: none;
            & + img.desktop {
              display: block;
              visibility: visible;
            }
          }
        }
      }
    }

    h3 {
      border-top: 1px solid $color-gray;
      padding-top: 11px;
      margin: 24px 0 0 0;
      transition: color .2s ease;
      @include media-breakpoint-up(xl) {
        padding-top: 14px;
      }
    }
  }
}