.breadcrumbs {
  margin: 0 0 24px 4px;
  [dir="rtl"] & {margin-right: 8px;margin-left: 0;}
  @include media-breakpoint-up(xl) {
    margin-bottom: 64px;
  }

  ol {
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: 27px;
    cursor: default;
    @include media-breakpoint-up(xl) {
      line-height: normal;
    }

    li {
      display: inline;
      position: relative;

      &:not(.active) {
        padding-right: 20px;
        margin-right: 10px;

        &::after {
          @include pseudo;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent $color-gray;
          margin: auto;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
        }

        [dir="rtl"] & {
          padding-right: 0;
          padding-left: 20px;
          margin-right: 0;
          margin-left: 10px;
          &::after {
            right: auto;
            left: 0;
            border-width: 5px 5px 5px 0;
            border-color: transparent $color-gray transparent transparent;
          }
        }
      }

      &.active {
        font-weight: 500;
        color: $color-green;
      }
    }

    a {
      display: inline-block;
      color: $color-gray;
      text-decoration: none;
      @media (hover: hover) {
        &:hover, &:focus {
          color: $color-black;
        }
      }
    }
  }
}