.block {
  margin: 32px 0;
  @include media-breakpoint-up(md) {
    margin: 56px 0;
  }
  @include media-breakpoint-up(xl) {
    margin: 112px 0;
  }

  &__content {
    position: relative;
    @include media-breakpoint-down(md) {
      .col-lg-8 > & {
        .lead {
          margin-bottom: 32px;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      left: 6px;
      .col-md-6 > & {
        max-width: 584px;
        left: 0;
      }
    }


    h2 {
      margin-bottom: 8px;
    }

    > *:last-child {margin-bottom: 0;}

    .btn {
      margin-top: 10px;
    }

    .founder {
      margin-top: 24px;
      margin-bottom: 0;
      @include media-breakpoint-up(xl) {
        margin-top: 35px;
      }

      .lead {
        margin-bottom: 8px;
        @include media-breakpoint-up(xl) {
          margin-bottom: 12px;
        }
      }

      p:last-child {margin-bottom: 0;}
    }
  }

  &__image {
    padding: 0;
    margin: 32px 0 0 0;
    display: block;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 10px;
      [dir="rtl"] & {
        padding-right: 10px;
        padding-left: 0;
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      position: relative;
      object-fit: cover;
      object-position: top center;
      @include user-select;

      &.mobile {
        & + img.desktop {display: none;}
        @include media-breakpoint-up(md) {
          display: none;
          & + img.desktop {display: block;}
        }
      }
    }
  }

  &__gallery {
    margin-top: 30px;
    position: relative;
    &::after {@include clearfix;}
    @include media-breakpoint-up(xsl) {
      min-height: 400px;
    }
    @include media-breakpoint-up(md) {
      margin-top: 0;
      overflow: hidden;
    }
    @include media-breakpoint-up(xl) {
      min-height: 468px;
    }

    .block__image {
      padding: 0;
      margin: 0;
      @include media-breakpoint-down(xsl) {
        + .block__image {margin-top: 24px;}
      }
      @include media-breakpoint-up(xsl) {
        width: 50%;
        padding: 0;
        &:nth-child(1) {
          padding-right: 10px;float: left;
          img {height: 190px;}
        }
        &:nth-child(2) {
          padding-right: 10px;
          margin-top: 20px;
          float: left;
          clear: left;
          img {height: 190px;}
        }
        &:nth-child(3) {
          padding-left: 10px;
          float: right;
          position: absolute;
          top: 0;
          right: 0;
          img {height: 400px;}
        }
      }
      @include media-breakpoint-up(xl) {
        &:nth-child(1) {
          img {height: 224px;}
        }
        &:nth-child(2) {
          margin-top: 20px;
          img {height: 224px;}
        }
        &:nth-child(3) {
          img {height: 468px;}
        }
      }
      @include media-breakpoint-up(xxl) {
        &:nth-child(1) {
          padding-right: 25px;
          padding-left: 10px;
          [dir="rtl"] & {padding-right: 35px;padding-left: 0;}
          img {height: 204px;}
        }
        &:nth-child(2) {
          padding-right: 25px;
          padding-left: 10px;
          margin-top: 60px;
          [dir="rtl"] & {padding-right: 35px;padding-left: 0;}
          img {height: 204px;}
        }
        &:nth-child(3) {
          padding-left: 35px;
          [dir="rtl"] & {padding-right: 10px;padding-left: 25px;}
          img {height: 468px;}
        }
      }
    }

    .block--reverse & {
      @include media-breakpoint-up(xl) {
        min-height: 500px;
      }
      .block__image {
        @include media-breakpoint-up(xsl) {
          &:nth-child(1) {
            position: absolute;top: 0;left: 0;
            img {height: 400px;}
          }
          &:nth-child(2) {
            padding-right: 0;
            padding-left: 10px;
            margin-top: 0;
            float: right;
            clear: none;
          }
          &:nth-child(3) {
            margin-top: 20px;
            clear: right;
            position: static;
            img {height: 190px;}
          }
        }
        @include media-breakpoint-up(xl) {
          &:nth-child(1) {
            img {height: 468px;}
          }
          &:nth-child(2) {
            margin-top: 0;
          }
          &:nth-child(3) {
            margin-top: 20px;
            img {height: 224px;}
          }
        }
        @include media-breakpoint-up(xxl) {
          &:nth-child(1) {
            padding-right: 35px;
            padding-left: 0;
            [dir="rtl"] & {padding-right: 35px;padding-left: 0;}
            img {height: 500px;}
          }
          &:nth-child(2) {
            padding-right: 10px;
            padding-left: 25px;
            margin-top: 0;
            [dir="rtl"] & {padding-right: 35px;padding-left: 0;}
            img {height: 220px;}
          }
          &:nth-child(3) {
            padding-right: 10px;
            padding-left: 25px;
            margin-top: 60px;
            [dir="rtl"] & {padding-right: 35px;padding-left: 0;}
            img {height: 220px;}
          }
        }
      }
    }
  }

  // BLOCKS
  &s {
    &, .block {
      margin: 72px 0;
      @include media-breakpoint-up(xl) {
        margin: 112px 0;
      }
    }

    .block {
      &:first-child {margin-top: 0;}
      &:last-child {margin-bottom: 0;}
    }
  }

  // REVERSE
  &--reverse {
    & > .row {
      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
      }
    }
    .block__content {
      @include media-breakpoint-up(xl) {
        left: 10px;
        [dir="rtl"] & {
          left: 0;
          right: 10px;
        }
      }
    }
  }

  // ABOUT
  &--about {
    @include media-breakpoint-down(xl) {
      h2 {margin-bottom: -5px;}
      .lead {margin-bottom: 24px;font-size: 18px;line-height: 26px;}
    }
    @include media-breakpoint-up(xl) {
      margin-top: 115px;
    }
  }
}