.features {
  margin: 32px 0;
  @include media-breakpoint-up(md) {
    margin: 56px 0;
  }
  @include media-breakpoint-up(xl) {
    margin: 112px 0;
  }

  .marquee + & {
    @include media-breakpoint-down(md) {
      margin-top: 74px;
    }
  }

  > .row {
    [dir="rtl"] & {
      flex-direction: row-reverse;
    }
  }

  &__entry {
    margin-bottom: 32px;
    position: relative;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(xxl) {
    > .row > .col-md-4 {
      &:nth-child(1) .features__entry {padding-right: 14px;}
      &:nth-child(2) .features__entry {padding-right: 14px;left: 7px;}
      &:nth-child(3) .features__entry {padding-left: 14px;}
      [dir="rtl"] & {
        &:nth-child(1) .features__entry {padding-right: 0;padding-left: 14px;}
        &:nth-child(2) .features__entry {padding-right: 0;padding-left: 14px;left: 0;right: 7px;}
        &:nth-child(3) .features__entry {padding-left: 0;padding-right: 14px;}
      }
    }
  }

  p:last-child {margin-bottom: 0;}

  &__icon {
    width: 72px;
    height: 72px;
    border: 1px solid $color-gray;
    border-radius: 50%;
    padding: 0;
    margin: 0 0 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(xl) {
      width: 89px;
      height: 89px;
      margin-bottom: 30px;
    }

    svg {
      margin: auto;
      display: block;
      @include user-select;
    }
  }

  &__title {
    margin-bottom: 10px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 15px;
    }

    br {
      display: none;
      @include media-breakpoint-up(md) {
        @include media-breakpoint-down(xxl) {
          display: inline;
        }
      }
    }
  }

  &__text {
    padding-top: 25px;
    border-top: 1px solid $color-gray;
    @include media-breakpoint-up(xxl) {
      padding-left: 3px;
    }
  }
}