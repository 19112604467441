.ratings {
  margin-top: 32px;
  margin-bottom: 72px;
  position: relative;
  z-index: 1;

  [dir="rtl"] & {
    & > .row {
      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
      }
    }
  }

  &::after {
    @include pseudo;
    border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
    position: absolute;
    top: 0;
    right: -20px;
    bottom: 0;
    left: -20px;
    z-index: 1;
    @include media-breakpoint-up(md) {
      width: 100vw;
      max-width: $mid-width;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__entry {
    padding: 26px 0 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    position: relative;
    z-index: 2;
    cursor: default;
    @include media-breakpoint-up(md) {
      padding-top: 24px;
      padding-bottom: 24px;
      justify-content: flex-start;
      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }
    }
    @include media-breakpoint-up(xl) {
      padding-right: 10px;
      padding-left: 10px;
    }
    @include media-breakpoint-up(xxl) {
      padding-right: 20px;
      padding-left: 20px;
      font-size: 30px;
      font-weight: normal;
      justify-content: flex-start;
    }

    &::after {
      @include pseudo;
      height: 1px;
      border-bottom: 1px solid $color-gray;
      position: absolute;
      right: -20px;
      bottom: 0;
      left: -20px;
      z-index: 1;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__icon {
    padding: 0;
    margin: 0;
    display: block;
    position: relative;
    top: -3px;
    flex: 0 0 auto;
    @include media-breakpoint-up(md) {
      top: 0;
    }

    img, svg {
      display: block;
      @include user-select;
      @include media-breakpoint-down(xxl) {
        width: 48px;
        height: 48px;
      }
      @include media-breakpoint-up(md) {
        @include media-breakpoint-down(lg) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  &__name {
    width: 100%;
    margin: 0 16px 2px;
    white-space: nowrap;
    @include media-breakpoint-up(md) {
      @include media-breakpoint-down(lg) {
        margin: 0 12px;
      }
    }
    @include media-breakpoint-up(lg) {
      width: auto;
      margin-bottom: 0;
      @include media-breakpoint-up(xxl) {
        width: 100%;
      }
    }
  }

  &__number {
    padding-left: 30px;
    position: relative;
    display: block;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    flex: 0 0 auto;
    cursor: default;
    [dir="rtl"] & {
      padding-right: 30px;
      padding-left: 0;
    }
    @include media-breakpoint-up(md) {
      @include media-breakpoint-down(lg) {
        font-size: 18px;
      }
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 46px;
      font-size: 30px;
      [dir="rtl"] & {
        padding-right: 46px;
        padding-left: 0;
      }
    }

    &::before {
      @include pseudo;
      width: 22px;
      height: 22px;
      position: absolute;
      top: 2px;
      left: 0;
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMiAyMyI+PHBhdGggZmlsbD0iI0ZERDgzNiIgZD0ibTExLjA5Ni41IDMuMDYgNy42N0wyMiA4Ljk3N2wtNS45MyA1LjQ1IDEuNzIgOC4wNzMtNi42OTUtNC40NC02Ljg4NyA0LjQ0IDEuOTEzLTguMDczTDAgOC45NzdsOC4wMzUtLjgwN0wxMS4wOTUuNVoiLz48L3N2Zz4=') no-repeat top -2px left;
      background-size: 22px 22px;
      [dir="rtl"] & {
        right: 0;
        left: auto;
      }
      @include media-breakpoint-up(md) {
        top: 1px;
      }
      @include media-breakpoint-up(xl) {
        top: 4px;
      }
      @include media-breakpoint-up(xxl) {
        width: 35px;
        height: 37px;
        top: -5px;
        left: 2px;
        background-size: 35px 37px;
        [dir="rtl"] & {
          right: 2px;
          left: auto;
        }
      }
    }
  }
}
