// ==========================================================================
// VARIABLES
// ========================================================================== */
$min-width: 320px;
$mid-width: 1440px;
$max-width: 1920px;

// Media Breakpoints
$grid-breakpoints: (
  xs: 360px, // Small Screen
  xss: 375px, // iPhone SE
  xsm: 390px, // iPhone 14
  xsl: 430px, // iPhone Plus/Max
  sm: 540px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1640px
) !default;

// Paths
$img: '../img';
$fonts: '../fonts';

// Fonts
$font-default: 'Heebo', sans-serif;

// Colors
$color-black: #2a2a2a;
$color-white: #f1efe9;
$color-green: #4f8b12;
$color-gray: #838383;
$color-red: #d85a09;

// Transition Settings
$effect: .3s ease;
$effect-s: .2s ease;

// Mixins
@mixin pseudo {content: '';display: block;overflow: hidden;}
@mixin clearfix {content: '';display: table;clear: both;}
@mixin user-select {-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}

// SVG
$svg-arrow: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSI3IiBmaWxsPSJub25lIj48cGF0aCBzdHJva2U9IiMyQTJBMkEiIGQ9Ik0zMCAzLjVIMCIvPjxwYXRoIGZpbGw9IiMyQTJBMkEiIGQ9Ik0zMC4wMiA3IDMwIDBsNSAzLjUwOEwzMC4wMiA3WiIvPjwvc3ZnPg==';
$svg-select: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSI5IiBmaWxsPSJub25lIj48cGF0aCBmaWxsPSIjODM4MzgzIiBkPSJNMCAuMDM3IDEzIDAgNi40ODYgOSAwIC4wMzdaIi8+PC9zdmc+';