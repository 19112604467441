* {
  &, :before, :after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(black, 0);
  }
}

html {
  width: 100%;
  height: 100%;
  background: $color-white;
}

body {
  width: 100%;
  height: 100%;
  min-width: $min-width;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  //overflow-x: hidden;
  font: 300 18px/1.445em $font-default;
  color: $color-black;
  background: $color-white;
  -webkit-font-smoothing: antialiased;
  @include media-breakpoint-up(xl) {
    font-size: 22px;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

ul {
  margin-top: 0;
  margin-bottom: 1em;
}

a {
  color: $color-black;
  text-decoration: underline;
  text-decoration-thickness: .0625em;
  text-underline-offset: .167em;
  transition: color $effect;
  outline: none !important;
  @media (hover: hover) {
    &:hover, &:focus {text-decoration: none;}
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: .5em;
  font-family: $font-default;
  font-weight: 500;
  line-height: normal;
}
h1, .h1 {
  font-size: 60px;
  @include media-breakpoint-up(xl) {
    font-size: 70px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 80px;
  }
}
h2, .h2 {
  font-size: 55px;
  @include media-breakpoint-up(xl) {
    font-size: 65px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 75px;
  }
}
h3, .h3 {
  font-size: 25px;
  @include media-breakpoint-up(xl) {
    font-size: 30px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 35px;
  }
}
h4, .h4 {
  font-size: 20px;
  @include media-breakpoint-up(xl) {
    font-size: 25px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 30px;
  }
}
h5, .h5 {
  font-weight: 500;
}
h6, .h6 {
  font-weight: 300;
}

.lead {
  margin-bottom: 25px;
  font-size: 25px;
  line-height: 32px;
  font-weight: 500;
  @include media-breakpoint-up(xl) {
    margin-bottom: 32px;
    font-size: 35px;
    line-height: 51px;
  }

  strong {
    font-weight: 500;
  }
}