.index {
  padding-top: 8px;
  padding-bottom: 32px;
  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: 43px;
  }

  &__heading {
    margin: 0;
    @include media-breakpoint-down(md) {
      font-size: 55px;
      line-height: 120%;
      [dir="rtl"] & {
        font-size: 44px;
      }
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 1px;
      @include media-breakpoint-down(lg) {
        font-size: 50px;
      }
    }
    @include media-breakpoint-up(xl) {
      margin-top: -8px;
    }
  }

  &__title {
    margin: 0;
    font-size: 55px;
    line-height: 120%;
    @include media-breakpoint-down(md) {
      [dir="rtl"] & {
        font-size: 44px;
      }
    }
    @include media-breakpoint-up(md) {
      margin-left: 24px;
      [dir="rtl"] & {
        margin-left: 30px;
      }
      @include media-breakpoint-down(lg) {
        font-size: 40px;
      }
    }
    @include media-breakpoint-up(xl) {
      font-size: 70px;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 80px;
    }
  }

  &__two {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
      [dir="rtl"] & {
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }

  .btn {
    width: 100%;
    max-width: 320px;
    margin-top: 32px;
    @include media-breakpoint-up(md) {
      width: auto;
      max-width: none;
      margin-top: 0;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 48px;
      padding-left: 48px;
    }
  }
}