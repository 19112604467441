.brunches {
  margin: 32px 0;
  @include media-breakpoint-up(md) {
    margin: 56px 0 48px;
  }
  @include media-breakpoint-up(xl) {
    > .row {
      margin-right: -30px;margin-left: -30px;
      > .col-12 {padding-right: 30px;padding-left: 30px;}
    }
  }

  &__map {
    overflow: hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
  }
  &__map iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }

  &__entry {
    margin-bottom: 40px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 60px;
    }
  }

  &__header {
    padding: 10px 0 18px;
    @include media-breakpoint-up(xl) {
      padding-top: 11px;
      padding-bottom: 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__name {
    margin: 0;
    font-size: 55px;
    line-height: 62px;
    @include media-breakpoint-up(md) {
      @include media-breakpoint-down(lg) {
        font-size: 45px;
      }
    }
  }

  &__address {
    margin: 1px 0 0;
    font-size: 22px;
    line-height: 130%;
    font-weight: normal;
    @include media-breakpoint-up(xl) {
      font-size: 30px;
    }
  }

  &__schedule {
    padding: 8px 0;
    border-bottom: 1px solid #838383;
    @include media-breakpoint-up(xl) {
      display: flex;
      justify-content: space-between;
      gap: 30px;
    }

    > p {
      width: 100%;
      margin: 0;
      display: flex;
      justify-content: space-between;
    }
  }

  &__delivery {
    min-height: 84px;
    padding: 14px 0 6px;
    border-bottom: 1px solid #838383;
    margin: 0;
    display: flex;
    align-items: flex-end;
    font-size: 18px;
    line-height: 26px;
    @include media-breakpoint-up(xl) {
      min-height: 100px;
      padding: 6px 0;
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__buttons {
    margin-top: 32px;
    @include media-breakpoint-up(sm) {
      display: flex;
      [dir="rtl"] & {
        flex-direction: row-reverse;
      }
    }
    @include media-breakpoint-up(md) {
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }

    .btn {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      display: block;
      & + .btn {
        margin-top: 24px;
        @include media-breakpoint-up(sm) {
          margin-top: 0;
          margin-left: 20px;
        }
        @include media-breakpoint-up(md) {
          @include media-breakpoint-down(lg) {
            margin-top: 20px;
            margin-left: 0;
          }
        }
        @include media-breakpoint-up(xl) {
          margin-left: 32px;
        }
      }
    }
  }
}
