.hero {
  margin: 0 0 32px;

  &__heading {
    margin-bottom: 18px;
    @include media-breakpoint-up(md) {
      margin-bottom: 14px;
      .col-lg-4 & {margin-top: -12px;}
    }
    @include media-breakpoint-up(xl) {
      margin-left: 4px;
      .col-lg-4 & {margin-top: -24px;}
      [dir="rtl"] & {margin-left: 0;}
    }

    h2 {margin: 0;}
  }

  &__content {
    @include media-breakpoint-up(xl) {
      padding-left: 6px;
      [dir="rtl"] & {
        padding-left: 0;
        padding-right: 3px;
      }
    }

    p:last-child {margin-bottom: 0;}
  }
}