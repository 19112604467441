/* ==========================================================================
   HEADER
   ========================================================================== */
.header {
  width: 100%;
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
  height: 88px;
  min-height: 88px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  color: $color-black;
  @include media-breakpoint-up(xl) {
    height: 128px;
    min-height: 128px;
  }

  .container {
    max-width: 1920px;
    height: 100%;
    position: relative;
    z-index: 1;

    &::before {
      @include pseudo;
      border-bottom: 1px solid $color-gray;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      background: rgba($color-white, .98);
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
    }
  }

  &__logo {
    margin: 0;
    display: block;
    position: absolute;
    top: 27px;
    left: 20px;
    z-index: 5;
    outline: none;
    @include media-breakpoint-up(xl) {
      top: 49px;
      left: 43px;
    }

    img {
      display: block;
      @include user-select;
    }
  }

  &__delivery {
    position: absolute;
    top: 24px;
    right: 92px;
    z-index: 5;
    @include media-breakpoint-down(xs) {
      right: 72px;
    }
    @include media-breakpoint-down(xl) {
      width: 40px;
      min-width: 0;
      height: 40px;
      padding: 5px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        display: none;
      }
      span {
        display: none;
      }
    }
    @include media-breakpoint-up(xl) {
      min-width: 280px;
      padding-right: 30px;
      padding-left: 30px;
      top: 32px;
      right: 40px;
      text-align: center;
    }


    svg {
      margin: auto;
      display: block;
      @include user-select;
      @include media-breakpoint-up(xl) {
        display: none;
      }
    }
  }

  &__nav {
    padding: 8px 0 22px;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 1;
    @include media-breakpoint-down(xl) {
      background: rgba($color-white, .98);
      transition: transform $effect;
      transform: translate3d(0, -100%, 0);

      &.visible {
        &, & ~ .header__overlay {display: block;}
      }
      &.open {
        transform: translate3d(0, 0, 0);
        & ~ .header__overlay {opacity: 1;}
      }
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      background: none;
    }
  }

  &__menu {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 22px;
    font-weight: 300;
    text-align: left;
    position: relative;
    z-index: 1;
    @include media-breakpoint-down(xl) {
      [dir="rtl"] & {text-align: right;}
    }
    @include media-breakpoint-up(xl) {
      display: flex;
      align-items: center;
      position: absolute;
      top: 46px;
      left: 264px;
      [dir="rtl"] & {flex-direction: row-reverse;}
    }

    > li {
      position: relative;
      @include media-breakpoint-up(xxl) {
        padding: 0 18px;
      }

      &.active {
        > a {
          color: $color-green;
          font-weight: 500;
        }
      }

      &.dropdown {
        > a {
          &::after {
            @include pseudo;
            width: 0;
            height: 0;
            margin-left: 10px;
            margin-right: 4px;
            position: relative;
            top: -1px;
            display: inline-block;
            vertical-align: middle;
            border-style: solid;
            border-width: 9px 6px 0 6px;
            border-color: $color-gray transparent transparent transparent;
            [dir="rtl"] & {
              margin-left: 4px;
              margin-right: 10px;
            }
          }
        }

        &.active {
          > a {
            font-weight: 300;
            color: inherit;
            &::after {transform: scaleY(-1);}
          }
          > ul {
            display: block;
          }
        }
      }

      > ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: none;
        @include media-breakpoint-up(xl) {
          min-width: 228px;
          padding: 15px 4px;
          border: 1px solid $color-gray;
          margin-top: 2px;
          position: absolute;
          top: 100%;
          left: -22px;
          background: rgba($color-white, .98);
          [dir="rtl"] & {text-align: right;}
        }

        > li {
          > a {
            &::before {
              margin: 0 6px;
              display: inline;
              content: '–';
              @include media-breakpoint-up(xl) {
                display: none;
              }
            }
          }
        }
      }
    }

    a {
      padding: 13px 40px;
      display: block;
      position: relative;
      color: $color-black;
      text-decoration: none;
      white-space: nowrap;
      @include media-breakpoint-up(xl) {
        padding: 5px 15px;
      }

      &.button {
        padding: 12px 24px;
        border-radius: 8px;
        margin: 15px 0 0 0;
        display: inline-block;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        color: white;
        text-decoration: none;
        text-align: center;
        background: #008535;
        @include media-breakpoint-up(xl) {
          padding: 13px 37px 12px;
          margin: 0 0 0 16px;
          display: block;
          top: 0;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }

  &__lang {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: 22px;
    @include media-breakpoint-up(xl) {
      position: absolute;
      top: 45px;
      right: 343px;
      z-index: 3;
    }

    li {
      order: 1;
      display: none;
      &.active {
        order: 0;
        display: block;
      }
      @include media-breakpoint-up(xl) {
        &:not(.active) {
          padding: 5px;
          border: 1px solid $color-gray;
          background: rgba($color-white, .98);
        }
      }

      &.active > a {
        &::after {
          @include pseudo;
          width: 0;
          height: 0;
          margin-left: 11px;
          margin-right: 4px;
          position: relative;
          display: inline-block;
          vertical-align: middle;
          border-style: solid;
          border-width: 9px 6px 0 6px;
          border-color: $color-gray transparent transparent transparent;
          [dir="rtl"] & {
            margin-left: 4px;
            margin-right: 11px;
          }
        }
      }

      & + li {
        margin-top: -8px;
        @include media-breakpoint-up(xl) {
          margin-top: 0;
        }
      }
    }

    a {
      padding: 13px 40px;
      display: block;
      font-weight: 500;
      position: relative;
      text-decoration: none;
      @include media-breakpoint-up(xl) {
        padding: 5px 15px;
      }
    }

    &.active li {
      display: block;
    }
  }

  &__overlay {
    @include pseudo;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: -100px;
    left: 0;
    z-index: -1;
    background: rgba($color-black, .4);
    transition: opacity $effect;
    opacity: 0;
    @include media-breakpoint-up(xl) {
      display: none !important;
    }
  }

  &__toggle {
    width: 60px;
    height: 60px;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    z-index: 7;
    cursor: pointer;
    @include media-breakpoint-up(xl) {
      display: none;
    }

    i {
      width: 30px;
      height: 1px;
      margin: auto;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: black;
      transition: background-color .3s ease;
    }

    &::before, &::after {
      width: 30px;
      content: '';
      height: 1px;
      margin: auto;
      position: absolute;
      right: 0;
      left: 0;
      background: black;
      transition: transform .3s ease, background-color .3s ease;
    }
    &::before {top: 20px;}
    &::after {bottom: 20px;}

    .header__nav.open ~ & {
      i {background: transparent;}
      &::before {transform: translate(-1px, 10px) rotate(-45deg);}
      &::after {transform: translate(-1px, -9px) rotate(45deg);}
    }
  }
}
