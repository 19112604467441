/* ==========================================================================
   PAGE
   ========================================================================== */
.page {
  width: 100%;
  max-width: $max-width;
  padding-top: 112px;
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    padding-top: 128px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 168px;
  }
}