.menu {
  &__nav {
    padding: 23px 0;
    margin-bottom: 32px;
    border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        a {
          text-decoration: none;
          color: $color-black;
        }
      }
    }
  }

  &__section {
    margin-bottom: 80px;

    &s {
      @include media-breakpoint-up(xl) {
        margin: 0 -8px;
      }
    }

    &__header {
      height: 398px;
      margin-bottom: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &::after {
        @include pseudo;
        margin: 0 0 0 -50vw;
        width: 100vw;
        background: rgba(0,0,0, .4);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        z-index: 2;
      }

      h2 {
        margin: 0;
        color: $color-white;
        position: relative;
        z-index: 3;
      }

      figure {
        margin: 0 0 0 -50vw;
        width: 100vw;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          object-position: center center;

          &.mobile {
            & + img.desktop {
              display: none;
              visibility: hidden;
            }
            @include media-breakpoint-up(md) {
              display: none;
              & + img.desktop {
                display: block;
                visibility: visible;
              }
            }
          }
        }
      }
    }

    &__item {
      min-height: 109px;
      padding: 0 122px 6px 8px;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 14px;
      position: relative;

      &::after {
        @include pseudo;
        height: 1px;
        background: $color-gray;
        position: absolute;
        left: 8px;
        right: 8px;
        bottom: 0;
      }

      h4 {
        margin: 0 0 5px 0;
        font-size: 22px;
      }

      p {
        margin-bottom: 0;
        font-weight: 300;
        font-size: 22px;
      }

      strong {
        font-size: 22px;
        display: block;
        font-weight: 500;
        position: absolute;
        bottom: 6px;
        right: 12px;

        &::before {
          content: '₪'
        }
      }

      span {
        width: 35px;
        height: 35px;
        display: block;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyIgZmlsbD0ibm9uZSI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48cGF0aCBmaWxsPSIjRDg1QTA5IiBkPSJNMTAuOTU3IDguMzJWNS40ODNsLTEuNjY4LS43NjZjLTEuODUgMi4yLTIuNTQxIDcuOTMyLTcuMDE4IDcuOTMyYTEuNzcgMS43NyAwIDAgMCAwIDMuNTQyYzYuNzI0IDAgMTEuNzItMy44ODYgMTMuMjU1LTYuNjUyTDE0LjM1NSA4LjMyaC0zLjM5OFptNC4xNDUtNC4wNmMxLjAxNy0xLjg5Mi40ODgtMy40MjMtLjEtNC4xNjZhLjI1My4yNTMgMCAwIDAtLjM3Ny0uMDE4bC0uNzI0LjcyN2EuMjU1LjI1NSAwIDAgMC0uMDI4LjMyM2MuMjE3LjMyOC41NCAxLjExLS4wNDQgMi4yODdhNC41MTcgNC41MTcgMCAwIDAtMS44MDgtLjM3OCA0LjUyIDQuNTIgMCAwIDAtMi40MTQuNzE1bDIuMzYyIDEuMDg0djIuNDc0aDIuODE3bDEuNzI5IDEuNzk5Yy4xMDMtLjQuMTc2LS44MS4xNzYtMS4yNDNhNC44NyA0Ljg3IDAgMCAwLTEuNTg5LTMuNjAzWiIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0uNSAwaDE3djE3SC41eiIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg==') no-repeat center center;
        background-size: 17px auto;
        border: 1px solid $color-gray;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        right: 12px;
      }
    }
  }

}
