.block--contact {
  @include media-breakpoint-down(md) {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  > .row {
    align-items: center;
    .col-md-6 > {
      .block__content {max-width: none;}
    }
  }

  h2 {
    margin-bottom: 14px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 2px;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 110px;
    }
  }

  .contact-form {
    padding-top: 32px;
    @include media-breakpoint-up(md) {
      padding-top: 0;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 10px;
      [dir="rtl"] & {
        padding-left: 0;
        padding-right: 10px;
      }
    }
    @include media-breakpoint-up(xxl) {
      padding-top: 56px;
    }
  }

  .block__image {
    margin-top: 32px;
    @include media-breakpoint-down(xl) {
      @include media-breakpoint-up(md) {
        img {min-height: 180px;}
      }
    }
    @include media-breakpoint-up(xl) {
      padding-right: 10px;
      padding-left: 0;
      [dir="rtl"] & {
        padding-right: 0;
        padding-left: 10px;
      }
    }
  }
}

.contact-form {
  .btn {
    width: 100%;
    max-width: 320px;
    display: block;
    margin: 32px auto 0;
    @include media-breakpoint-up(md) {
      width: auto;
      max-width: none;
      min-width: 200px;
      margin: 0;
      display: inline-block;
    }
    @include media-breakpoint-up(xl) {
      min-width: 280px;
    }
  }
}