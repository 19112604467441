.faq {
  margin: 43px 0 32px;
  @include media-breakpoint-up(xl) {
    margin: 60px 0;
  }

  &__question {
    padding-bottom: 10px;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
    @include media-breakpoint-up(xl) {
      padding-bottom: 26px;
      font-size: 22px;
      line-height: normal;
    }

    a {
      padding-right: 100px;
      display: block;
      position: relative;
      text-decoration: none;
      [dir="rtl"] & {
        padding-right: 0;
        padding-left: 100px;
      }

      &::before, &::after {
        @include pseudo;
        width: 30px;
        height: 1px;
        margin: auto;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 14px;
        background: $color-black;
        transition: transform .4s ease, opacity .3s;
        [dir="rtl"] & {
          right: auto;
          left: 0;
        }
      }
      &::before {
        transform: rotate(90deg);
      }
    }
  }

  &__answer {
    padding-bottom: 16px;
    display: none;
    @include media-breakpoint-up(xl) {
      padding-right: 100px;
      [dir="rtl"] & {
        padding-right: 0;
        padding-left: 100px;
      }
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &__item {
    border-bottom: 1px solid $color-black;
    margin-bottom: 44px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 60px;
    }

    &.active {
      .faq__question {
        a {
          &::before {transform: rotate(0deg);}
          &::after {transform: rotate(-90deg);opacity: 0;}
        }
      }
    }
  }
}