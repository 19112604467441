/* ==========================================================================
   FOOTER
   ========================================================================== */
.footer {
  width: 100%;
  max-width: $max-width;
  padding: 10px 0;
  margin: auto auto 0;
  position: relative;
  z-index: 1;
  color: $color-white;
  background: $color-green;
  cursor: default;

  > .container {
    @include media-breakpoint-up(xxxl) {
      max-width: $max-width;
    }

    > .row {
      &:nth-child(2) {
        @include media-breakpoint-up(md) {
          align-items: center;
        }
      }
      [dir="rtl"] & {
        &:nth-child(2), &:nth-child(3) {
          direction: ltr;
        }
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    @media (hover: hover) {
      &:hover, &:focus {
        color: white;
        text-decoration: none;
      }
    }
  }

  p:last-child {margin-bottom: 0;}

  &__nav {
    padding: 0 18px 6px;
    margin: 0 -20px 41px;
    position: relative;
    display: flex;
    justify-content: space-between;
    list-style: none;
    [dir="rtl"] & {
      flex-direction: row-reverse;
    }
    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
    @include media-breakpoint-up(md) {
      margin-right: 0;
      margin-left: 0;
    }
    @include media-breakpoint-up(xl) {
      padding: 24px 39px 12px 34px;
      margin-bottom: 60px;
    }

    &::after {
      @include pseudo;
      height: 1px;
      border-bottom: 1px solid $color-white;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    a {
      padding: 7px;
      display: block;
      white-space: nowrap;
      @media (hover: hover) {
        &:hover, &:focus {
          font-weight: 400;
          letter-spacing: -.02em;
        }
      }
    }
  }

  &__logo {
    padding: 0;
    margin: 0;
    @include media-breakpoint-up(xl) {
      margin-left: 33px;
    }

    img, svg {
      margin: 0 auto;
      display: block;
      @include user-select;
      @include media-breakpoint-up(xl) {
        margin: 0;
      }
    }
  }

  &__title {
    max-width: 276px;
    margin: 17px auto 0;
    text-align: center;
    @include media-breakpoint-up(xl) {
      margin-left: 27px;
      margin-right: 0;
    }
  }

  &__contacts {
    margin: 24px 0 0;
    font-size: 25px;
    line-height: 48px;
    font-weight: 500;
    text-align: center;
    @include media-breakpoint-up(xl) {
      margin-top: 0;
      font-size: 35px;
      line-height: 75px;
    }
  }

  &__social {
    margin: 34px auto;
    @include media-breakpoint-up(xl) {
      margin: 0 45px 0 0;
      display: flex;
      justify-content: flex-end;
    }

    a {
      width: 138px;
      height: 138px;
      border: 1px solid $color-white;
      border-radius: 50%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      transition: color .2s ease, background-color .2s ease;
      @include media-breakpoint-up(xl) {
        width: 160px;
        height: 160px;
        margin: 0 40px 0 0;
      }

      @media (hover: hover) {
        &:hover, &:focus {
          color: $color-green;
          background: $color-white;
        }
      }
    }
  }

  &__copyright {
    border-top: 1px solid $color-white;
    padding-top: 12px;
    margin: 17px 0 0;
    position: relative;
    line-height: 42px;
    text-align: center;
    display: flex;
    justify-content: space-between;

    //&::after {@include clearfix;}
    @include media-breakpoint-up(xl) {
      padding-top: 15px;
      margin-top: 60px;
    }

    span {
      display: block;
      //@include media-breakpoint-up(md) {
      //  &.left {float: left;}
      //  &.right {float: right;}
      //}
    }
  }
}
