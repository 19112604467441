.marquee {
  font-size: 55px;
  line-height: 110px;
  font-weight: 500;
  @include media-breakpoint-up(xl) {
    font-size: 65px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 75px;
  }

  &__space {
    padding: 0 20px;
    border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
    margin: 0 -20px;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    @include user-select;
    @include media-breakpoint-up(xss) {
      width: 100vw;
      max-width: 1920px;
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);
      [dir="rtl"] & {
        left: auto;
        right: 50%;
        transform: translateX(50%);
      }
    }
    @include media-breakpoint-up(xl) {
      padding: 24px 40px 22px;
    }
    [dir="rtl"] & {
      direction: ltr;
    }
  }

  span {
    display: inline-block;
    position: relative;

    padding-left: 20px;
    margin-left: 20px;
    @include media-breakpoint-up(xl) {
      padding-left: 32px;
      margin-left: 32px;
    }

    &::before {
      @include pseudo;
      width: 1px;
      position: absolute;
      top: 20px;
      bottom: 20px;
      left: 0;
      background: $color-gray;
    }
  }
}